<script>
// import Stat from "./widget";
// import RevenueAnalytics from "./revenue";
// import SalesAnalytics from "./sales-analytics";
// import EarningReport from "./earning";
// import Sources from "./sources";
// import RecentActivity from "./recent-activity";
// import RevenueLocation from "./revenue-location";
// import Chat from "./chat";
import Settings from "./settings";

/**
 * Dashboard component
 */
export default {
  components: {
    Settings,
  },
  data() {
    return {

    };
  },
};
</script>

<template>
  <div class="col-lg-12">
    <Settings />
  </div>
</template>
